import React from "react";
import { css } from "@emotion/react";
import { Box, Link, PseudoBox, Text, Stack } from "pws-design-system/design-system";
import { Link as ReachLink } from "gatsby";
import FadeIn from "../../../common/components/fade-in/";
import { LogoutLink } from "../../components/logout-link";
import {
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow
} from "@chakra-ui/core";
import { useAuthTokenStore } from "../../hooks/stores/useAuthTokenStore";

export default function DashboardDropdownDisplay({
  user,
  isActive,
  clickHandler,
  styleVariant,
  ...rest
}) {
  const { authToken } = useAuthTokenStore();
  if (user.hasData === true && user.isAuthenticated === true) {
    return (
      <FadeIn duration={0.05}>
        <Popover placement="bottom" returnFocusOnClose={false} closeOnBlur={true} usePortal>
          <PopoverTrigger>
            <div cy="login-link" tabIndex="0">
              <Avatar mt="-11px" cursor="pointer" size="sm" src="" />
            </div>
          </PopoverTrigger>
          <PopoverContent
            width="200px"
            color="brand.gray.base"
            fontSize="sm"
            fontWeight="semibold"
            zIndex={4}
            css={css`
              border: 0;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
              border-radius: 0.75em;
              :focus {
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
              }
            `}
          >
            <PopoverArrow bg="brand.lightBlue.base" />
            <Box borderRadius="xl" overflow="hidden">
              <PopoverHeader
                p={0}
                css={css`
                  border-radius: 0.75em;
                  border-bottom: 0;
                `}
              >
                <Box px={3} py={2} bg="brand.lightBlue.base" color="white">
                  <Text
                    mt={3}
                    variant="caption2"
                    color="brand.darkBlue.base"
                    fontWeight="bold"
                    textTransform="uppercase"
                    letterSpacing="wider"
                  >
                    Logged in as
                  </Text>
                  <Text variant="callout" fontWeight="bold">
                    {user.getData("response.user.username")}
                  </Text>
                </Box>
              </PopoverHeader>
              <PopoverBody
                py={3}
                borderColor="brand.lightBlue.base"
                borderStyle="solid"
                borderLeftWidth="4px"
                borderRightWidth="4px"
                borderBottomWidth="4px"
                borderRadius="0 0 0.75em 0.75em"
              >
                <Stack>
                  <Link
                    href={`${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authToken}`}
                  >
                    Manage Stations
                  </Link>
                  <LogoutLink />
                </Stack>
                <Box mt={2} mb={3} bg="brand.gray.300" height="1px" />
                <Text
                  variant="caption2"
                  color="brand.darkBlue.base"
                  fontWeight="bold"
                  textTransform="uppercase"
                  letterSpacing="wider"
                >
                  Stations
                </Text>
                <Stack mt={2} spacing={2}>
                  {user.stations &&
                    user.stations.map((station: any) => (
                      <Link
                        variant="dark"
                        key={station.stationId.toLowerCase()}
                        to={`/station/pws/${station.stationId.toLowerCase()}`}
                        as={ReachLink}
                        {...rest}
                      >
                        {station.stationId}
                      </Link>
                    ))}
                </Stack>
              </PopoverBody>
            </Box>
          </PopoverContent>
        </Popover>
      </FadeIn>
    );
  }

  if (user.hasData === true && user.isAuthenticated === false) {
    return (
      <Link as={ReachLink} to="/login" variant={styleVariant} {...rest}>
        Log In
      </Link>
    );
  }

  return <Box width="32px" height="21px" />;
}
