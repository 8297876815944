import Model from "../Model";
import * as yup from "yup";
import _isNil from "lodash.isnil";
import _get from "lodash.get";
import Link from "../link/";
// do not remove. Gatsby needs this to pick up the fragment and make available everywhere.
import * as fragments from "./query";
import schema from "./schema";

export class Announcement extends Model {
  link: Link = null;
  schema = schema;
  constructor(apiData: any) {
    super(apiData);
    const linkData = _get(apiData, "announcement_link");
    if (linkData.link_type !== "Any") {
      this.link = new Link({
        link: linkData,
        link_text: apiData.announcement_text
      });
    }
  }
  get text() {
    return this.get("announcement_text");
  }
  get color() {
    return this.get("announcement_color").toLowerCase();
  }
  get uid() {
    return this.get("announcement").toLowerCase();
  }
}

export class Announcements {
  models: Announcement[] = null;
  constructor(apiData: any) {
    const edges = _get(apiData, "announcement.edges", []);
    this.models = edges.map((edge: any) => new Announcement(_get(edge, "node.data")));
  }
}
