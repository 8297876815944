import AerisWeather from "@aerisweather/javascript-sdk";
import { isBrowser } from "../utils/";

if (isBrowser() === true) {
  window.__AERIS = {
    versions: {
      "@aerisweather/javascript-sdk": process.env.GATSBY_AERIS_SDK_VERSION
    }
  };
}

const API_KEYS = {
  clientId: process.env.GATSBY_AERIS_CLIENT_ID,
  clientSecret: process.env.GATSBY_AERIS_CLIENT_SECRET
};

const aeris = new AerisWeather(API_KEYS.clientId, API_KEYS.clientSecret, {
  api: process.env.GATSBY_AERIS_API_SERVER,
  maps: process.env.GATSBY_AERIS_MAP_SERVER
});
export default aeris;
