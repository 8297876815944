import _isNil from "lodash.isnil";
import _get from "lodash.get";
import _every from "lodash.every";
import _startCase from "lodash.startcase";
import _upperCase from "lodash.uppercase";
import _lowerCase from "lodash.lowercase";
import _noop from "lodash.noop";

type ModelProps = any & {
  data?: any;
  rawData?: any;
};

export default abstract class Model {
  protected basePath: string = null;
  public data: any = null;
  public rawData: any = null;
  public isModel = true;

  constructor(config: ModelProps = { data: null, rawData: null }) {
    if (!config) {
      return;
    }
    this.data = config.data;
    this.rawData = config.rawData;
  }

  public get hasData(): boolean {
    return _isNil(this.data) !== true;
  }

  public getData(path: string): any {
    if (_isNil(this.basePath) === false) {
      return _get(this.data, `${this.basePath}.${path}`, null);
    }
    return _get(this.data, `${path}`, null);
  }

  public get(
    attribute: string,
    fallback: any = "--",
    formatter: (value: any) => any = val => val,
    fallBackFormatter: (value: any) => any = val => val
  ): any {
    return this.has(attribute) === false ? fallBackFormatter(fallback) : formatter(this[attribute]);
  }

  public has(attribute: string): boolean {
    return _isNil(this[attribute]) === false;
  }

  public hasAll(attributes: string[]): boolean {
    return _every(attributes.map(attribute => this.has(attribute)));
  }
}
