import React, { FC } from "react";
import { motion } from "framer-motion";
import LinkModel from "../../../../models/prismic/link/";
import PrismicLink from "../prismic-link/";
import _noop from "lodash.noop";
import _isNil from "lodash.isnil";
import { Stack } from "pws-design-system/design-system";

type AnyType = any;

interface NavigationProps extends AnyType {
  links: LinkModel[];
  variant?: "dark" | "light";
  includeHome?: boolean;
  SuffixLinkSlot?: FC<any>;
  rest?: any;
  variants?: any;
}

export default function Navigation({
  links,
  variant = "dark",
  includeHome = true,
  SuffixLinkSlot = () => {
    return <></>;
  },
  inline = false,
  variants,
  ...rest
}: NavigationProps & any): React.ReactElement {
  if (inline) {
    return (
      <Stack spacing={4} fontSize="sm" fontWeight="bold" align="center" isInline>
        {links
          .filter((link: LinkModel) => (includeHome === true ? true : link.url !== "/"))
          .map((link: LinkModel) => {
            if (_isNil(variants) === false) {
              return (
                <motion.div variants={variants} key={link.text}>
                  <PrismicLink link={link} variant={variant} {...rest} />
                </motion.div>
              );
            } else {
              return (
                <PrismicLink
                  style={{ whiteSpace: "nowrap" }}
                  link={link}
                  variant={variant}
                  key={link.text}
                  {...rest}
                />
              );
            }
          })}
        {SuffixLinkSlot(rest)}
      </Stack>
    );
  }
  return (
    <>
      {links
        .filter((link: LinkModel) => (includeHome === true ? true : link.url !== "/"))
        .map((link: LinkModel) => {
          if (_isNil(variants) === false) {
            return (
              <motion.div variants={variants} key={link.text}>
                <PrismicLink link={link} variant={variant} {...rest} />
              </motion.div>
            );
          } else {
            return <PrismicLink link={link} variant={variant} key={link.text} {...rest} />;
          }
        })}
      {SuffixLinkSlot(rest)}
    </>
  );
}
