import { getDistance, getCompassDirection } from "geolib";
import * as strings from "@aerisweather/javascript-sdk/dist/utils/strings";
import Model from "../../Model";
import { StationType, StationTypePrefix } from "./constants";
import { metersToKilometers, metersToMiles } from "../../aeris/units";
import _isNil from "lodash.isnil";
import _get from "lodash.get";
import _startCase from "lodash.startcase";
import _upperCase from "lodash.uppercase";

export default class Place extends Model {
  placeId: string = null;
  constructor(
    config: { placeId?: string; data: any } = {
      placeId: null,
      data: null
    }
  ) {
    super(config);
    this.placeId = config.placeId;
  }
  get id() {
    return _isNil(this.placeId) === true ? null : this.placeId.toLowerCase();
  }
  get type(): Type | null {
    if (this.has("id")) {
      if (this.id.toLowerCase().startsWith(StationTypePrefix.Pws)) {
        return StationType.Pws;
      }
      if (this.id.toLowerCase().startsWith(StationTypePrefix.Madis)) {
        return StationType.Madis;
      }
      if (this.id.toLowerCase().startsWith(StationTypePrefix.Aus)) {
        return StationType.Aus;
      }
      if (this.id.toLowerCase().length === 4) {
        return StationType.Metar;
      }
    }
    return null;
  }
  get displayId() {
    if (this.has("id")) {
      if (
        this.id.startsWith(StationTypePrefix.Pws) ||
        this.id.startsWith(StationTypePrefix.Madis) ||
        this.id.startsWith(StationTypePrefix.Aus)
      ) {
        return this.id.slice(4);
      }
      return this.id;
    }
    return null;
  }
  get formattedPlace(): string | null {
    let country: string = _upperCase(this.country);
    if (country && country !== "US") {
      country = _startCase(strings.countries()[country.toLowerCase()]);
    }

    const str = [_startCase(this.name), _upperCase(this.state), country].filter(item => item);

    if (str.length !== 0) {
      return str.join(", ");
    }
    return null;
  }
  get name() {
    return this.getData("place.name");
  }
  get elevationFT() {
    return this.getData("profile.elevFT");
  }
  get elevationM() {
    return this.getData("profile.elevM");
  }
  get formattedPlaceShort() {
    if (this.name !== null) {
      return _startCase(this.name);
    }
    return null;
  }
  get state() {
    return this.getData("place.state");
  }
  get country() {
    return this.getData("place.country");
  }
  get stateFull() {
    return this.getData("place.stateFull");
  }
  get countryFull() {
    return this.getData("place.countryFull");
  }

  get lat() {
    return this.getData("loc.lat");
  }
  get lon() {
    return this.getData("loc.long");
  }
  get latLonArray() {
    return [this.lat, this.lon];
  }
  get latAndLonString() {
    if (this.hasAll(["lat", "lon"])) {
      return `${Number(this.lat).toFixed(2)}, ${Number(this.lon).toFixed(2)}`;
    }
    return null;
  }
  get url() {
    if (this.hasAll(["country", "name"])) {
      const parts = [this.country, this.state, this.name];
      const filtered = parts
        .filter(item => _isNil(item) !== true && item !== "")
        .map(item => item.toLowerCase());
      return filtered.join("/");
    }
    return null;
  }
  get timezone() {
    return this.getData("profile.tz");
  }
  get timeZoneOffset() {
    return this.getData("profile.tzoffset");
  }
  get timeZoneOffsetInMinutes() {
    return this.timeZoneOffset === null ? null : (this.timeZoneOffset / 60) * -1;
  }

  distanceTo(place: Place, isMetric: boolean = false, roundingFunction = Math.round): number {
    const coords = [this, place].map(place => {
      return { latitude: place.lat, longitude: place.lon };
    });

    const converter = isMetric === true ? metersToKilometers : metersToMiles;
    return roundingFunction(converter(getDistance(...coords)));
  }

  directionTo(place: Place): string {
    const coords = [this, place].map(place => {
      return { latitude: place.lat, longitude: place.lon };
    });
    return getCompassDirection(...coords);
  }
}
