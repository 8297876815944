import React from "react";
import { motion } from "framer-motion";

type FadeInProps = {
  /**
   * Duration in seconds
   * @type {number}
   */
  duration?: number;
  /**
   * @type {React.ReactNode}
   */
  children: React.ReactNode;
};

/**
 * Fade an element in on render. Good to reduce jarring transition for
 * client rendered elements.
 * @param {FadeInProps} { duration, children }
 * @returns {React.ReactElement}
 */
const FadeIn: React.FC<FadeInProps> = ({ duration = 0.1, children }): React.ReactElement => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration }}>
      {children}
    </motion.div>
  );
};

export default FadeIn;
