import React, { useState, useEffect } from "react";
import { mediaRangeQueries } from "pws-design-system/design-system/theme";
import _debounce from "lodash/debounce";
import _intersection from "lodash/intersection";

const isBrowser = typeof window !== "undefined" && window;

export type mediaQueryNames =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "print"
  | "mobile"
  | "tablet"
  | "desktop"
  | "desktop-up"
  | "tablet-up";

const checkMatches = (): any[] => {
  return Object.keys(mediaRangeQueries).filter(key => {
    if (isBrowser) {
      return window.matchMedia(mediaRangeQueries[key].replace("@media ", "")).matches;
    }
    return true;
  });
};

export const matchesMediaQuery = (size: mediaQueryNames | mediaQueryNames[]): boolean => {
  const matches = checkMatches();
  const castSizes = Array.isArray(size) ? size : [size];
  return _intersection(matches, castSizes).length > 0;
};

export function useMatchMediaQuery(delay = 700) {
  const [matches, setMatches] = useState(checkMatches());

  const handleResize = () => {
    setMatches(checkMatches());
  };

  const debouncedHandleResize = _debounce(handleResize, delay, {
    leading: true
  });

  useEffect(() => {
    debouncedHandleResize();
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return matches;
}

export const responsiveValue = (values: { [key: string]: any }): any => {
  let result: any;
  const mediaQueryMatches = useMatchMediaQuery();

  Object.keys(values).forEach(breakpoint => {
    if (_intersection(mediaQueryMatches, [breakpoint]).length > 0) {
      result = values[breakpoint];
    }
  });

  return result;
};

interface ResponsiveRenderProps {
  size: mediaQueryNames | mediaQueryNames[];
  children: React.ReactNode;
}

export default function ResponsiveRender({
  size,
  children
}: ResponsiveRenderProps): React.ReactElement {
  const castSizes = Array.isArray(size) ? size : [size];
  const mediaQueryMatches = useMatchMediaQuery();
  if (_intersection(mediaQueryMatches, castSizes).length > 0) {
    return <>{children}</>;
  }
  return null;
}
