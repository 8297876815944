import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";
import NavigationModel from "../../../../models/prismic/navigation";
import { Stack } from "pws-design-system/design-system";
import Navigation from "../../components/navigation/";
import LoginLink from "../../components/login-link";

function useQuery(): {
  mainNavigation: {};
  footerNavigation: {};
} {
  const data = useStaticQuery(
    graphql`
      query {
        ...MainNavigation
      }
    `
  );
  const linkData = _get(data, "mainNavigation.edges[0].node.data", null);
  return linkData;
}

interface NavigationProps {
  variant?: "dark" | "light";
}

export default function({ variant = "dark" }: NavigationProps): React.ReactElement {
  const mainNavigationData = useQuery();
  const mainNavigationModel = new NavigationModel(mainNavigationData);
  return (
    <Navigation
      inline={true}
      variant={variant}
      links={mainNavigationModel.links}
      includeHome={false}
      SuffixLinkSlot={props => <LoginLink styleVariant={variant} />}
    />
  );
}
