import React from "react";
import { Logo, BlockLink } from "pws-design-system/design-system";
import { Link as ReachLink } from "gatsby";

interface LogoProps {
  height: number;
}

export default function({ height = 60 }: LogoProps): React.ReactElement {
  const isBrowser = typeof window !== "undefined" && window;
  let isHome = false;
  if (isBrowser) {
    isHome = window.location.pathname === "/";
  }
  return (
    <>
      {isHome === false ? (
        <BlockLink as={ReachLink} to="/">
          <Logo variantColor="light" height={height} />
        </BlockLink>
      ) : (
        <Logo variantColor="light" height={height} />
      )}
    </>
  );
}
