import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import { Box, Flex, Text, Theme, Link } from "pws-design-system/design-system";
import ModalPanel, { ModalPanelNavigation } from "../../components/modal-panel/ModalPanel";
import HomeLogoLink from "../../../pages/place/LogoHomeLink";
import NavigationModel from "../../../../models/prismic/navigation";
import AutoSuggest from "../../components/AutoSuggest";
import LoginLink from "../../components/login-link";
import Navigation from "../../components/navigation/";
import _get from "lodash.get";
import { ThemeContainer } from "../../hooks/useTheme";

interface HamburgerMenuProps {
  isOpen: boolean;
  handleClose: () => void;
}

function useQuery(): {
  mainNavigation: {};
  footerNavigation: {};
} {
  const data = useStaticQuery(
    graphql`
      query {
        ...MainNavigation
        ...FooterSubnavigation
      }
    `
  );

  return Object.keys(data).reduce(
    (prev, curr) => {
      const item = data[curr];
      const itemData = _get(item, "edges[0].node.data", null);
      (prev as any)[curr] = itemData;
      return prev;
    },
    { mainNavigation: null, footerNavigation: null }
  );
}

export default function HamburgerMenu({
  isOpen,
  handleClose
}: HamburgerMenuProps): React.ReactElement {
  const { mainNavigation, footerNavigation } = useQuery();
  const navigation = new NavigationModel(mainNavigation);
  const subNavigation = new NavigationModel(footerNavigation);
  const { theme } = ThemeContainer.useContainer();

  const handleAutoSuggestResultClick = (location: any) => {
    if (location.placeType === "location") {
      window.location.assign(`/local/${location.url}`);
    } else {
      window.location.assign(`/station/${location.url}`);
    }
  };

  const easing = [0.77, 0.0, 0.175, 1.0];
  const navVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        ease: easing
      }
    },
    closed: {
      opacity: 0,
      x: 50,
      transition: {
        ease: easing
      }
    }
  };

  return (
    <ModalPanel
      bg="#1f4968"
      isActive={isOpen}
      handleClose={handleClose}
      navigation={ModalPanelNavigation.close}
    >
      <Box p={3} pt={5} pb={4}>
        <Box width="100%">
          <Flex mb={4} justify="center">
            <HomeLogoLink />
          </Flex>
          <AutoSuggest
            resizeOnFocus={false}
            showOverlay={true}
            resultsOrientation="vertical"
            theme={theme}
            fieldStyles={{
              base: {
                border: "4px solid transparent",
                height: "44px"
              },
              focus: {
                borderColor: "#51b7d4",
                _hover: {
                  borderColor: "#51b7d4"
                }
              }
            }}
            handleResultClick={handleAutoSuggestResultClick}
          />
        </Box>
        <Box fontSize="3xl" fontWeight="bold" width="full" color="#51b7d4" mt={4} mb={5} pt={3}>
          <Navigation
            links={navigation.links}
            SuffixLinkSlot={(props: any) => (
              <motion.div variants={navVariants}>
                <LoginLink variant="Hamburger" styleVariant="light" pl={3} {...props} />
              </motion.div>
            )}
            mb={4}
            pl={3}
            display="inline-block"
            isActiveCss={css`
              color: white;
              border-left: 6px solid ${Theme.colors.brand.green.base};
              padding-left: 10px;
            `}
            variant="light"
            variants={navVariants}
          />
        </Box>
        <Flex align="center" direction="column" color="brand.white.base">
          <Text fontSize="sm" mb={2} color="#8fa4b4">
            © {new Date().getFullYear()}{" "}
            <Link href="https://www.xweather.com" target="_blank">
              Vaisala Xweather
            </Link>
          </Text>
          <Flex
            align="center"
            css={css`
              > * + * {
                margin-left: 16px;
              }
            `}
          >
            <Navigation
              links={subNavigation.links}
              variant="light"
              fontWeight="bold"
              color="#8fa4b4"
            />
          </Flex>
        </Flex>
      </Box>
    </ModalPanel>
  );
}
