import * as strings from "@aerisweather/javascript-sdk/dist/utils/strings";
import Place from "./Place";
import _startCase from "lodash.startcase";
import _upperCase from "lodash.uppercase";
import _isNil from "lodash.isnil";
import Path from "../../path/Path";

export default class Location extends Place {
  placeType = "location";
  get displayId() {
    if (this.hasAll(["name", "state", "country"]) === false) {
      return null;
    }

    const parts = [_startCase(this.name), _upperCase(this.state)];
    if (this.country !== "US") {
      const country = _startCase(strings.countries()[this.country.toLowerCase()]);
      parts.push(country);
    }

    const filtered = parts.filter(item => _isNil(item) !== true && item !== "");
    if (filtered.length !== 0) {
      return filtered.join(", ");
    }
    return null;
  }

  get url() {
    if (this.hasAll(["country", "name"])) {
      const parts = [this.country, this.state, this.name];
      const filtered = parts
        .filter(item => _isNil(item) !== true && item !== "")
        .map(item => item.toLowerCase());
      return filtered.join("/");
    }
    return null;
  }
}
