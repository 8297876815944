import _isNil from "lodash.isnil";
import _get from "lodash.get";
import * as yup from "yup";
import uuid from "uuid/v4";

export default abstract class Model {
  protected abstract schema: yup.ObjectSchema;
  public uuid: string = null;
  constructor(protected apiData: object = null) {
    this.uuid = uuid();
  }
  public get(path: string): any {
    return _get(this.apiData, path, null);
  }
  public has(path: string): boolean {
    return _isNil(_get(this, path)) !== true;
  }
  public get isValid(): boolean {
    return this.schema.isValidSync(this.apiData); // TODO
  }
  public validate(): this {
    this.schema.validateSync(this.apiData);
    return this;
  }
}
