import Place from "./Place";
import _startCase from "lodash.startcase";
import _upperCase from "lodash.uppercase";
import _isNil from "lodash.isnil";
import { StationType, StationTypePrefix } from "./constants";

export default class Station extends Place {
  placeType = "station";
  get id() {
    return _isNil(this.placeId) === true ? this.getData("id") : this.placeId.toLowerCase();
  }
  get displayId() {
    if (this.has("id")) {
      if (
        this.id.toLowerCase().startsWith(StationTypePrefix.Pws) ||
        this.id.toLowerCase().startsWith(StationTypePrefix.Madis) ||
        this.id.toLowerCase().startsWith(StationTypePrefix.Aus)
      ) {
        return this.id.slice(4);
      }
      return this.id;
    }
    return null;
  }
  get url() {
    if (this.type !== null) {
      let type = this.type;
      if (this.type === "metar") {
        return this.displayId.toLowerCase();
      }
      if (this.type === "madis") {
        return `mid/${this.displayId.toLowerCase()}`;
      }
      return `${type}/${this.displayId.toLowerCase()}`;
    }
    return `${this.displayId.toLowerCase()}`;
  }
}
