import Station from "./Station";
import Collection from "../../Collection";
import _get from "lodash.get";

export default class Locations extends Collection {
  constructor(config = {}) {
    super(config);
    const data = _get(config, "data", null);
    if (data !== null && data.length > 0) {
      this.models = data.map(datum => new Station({ data: datum }));
    }
  }
}
