import Model from "../Model";

export default class UserModel extends Model {
  get isAuthenticated() {
    return this.getData("success") === true;
  }
  get hasStations() {
    return this.stations !== null && this.stations.length > 0;
  }
  get isMultiStation() {
    return this.stations !== null && this.stations.length > 1;
  }
  get stations() {
    return this.getData("response.stations");
  }
}
