import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Absolute, Box, Icon, Flex, Theme } from "pws-design-system/design-system";
import { themeGet } from "pws-design-system/design-system/utils/index";
import { Z } from "../../../../types/enums";

export enum ModalPanelNavigation {
  back = "back",
  close = "close"
}

export type ModalPanelProps = any & {
  navigation?: ModalPanelNavigation;
  isActive: boolean;
  disableBodyScrollOnActive?: boolean;
  handleClose?: () => void;
};

const ModalPanel = ({
  children,
  navigation,
  isActive,
  disableBodyScrollOnActive = true,
  handleClose,
  ...rest
}: ModalPanelProps): React.ReactElement => {
  const modalRef: any = useRef();
  const controls = useAnimation();
  let hasPresented = false;

  const handleCloseClick = () => {
    controls.start("closed");
  };

  const onAnimationComplete = () => {
    if (isActive && hasPresented) {
      if (disableBodyScrollOnActive) {
        enableBodyScroll(modalRef);
      }
      handleClose();
    } else {
      if (disableBodyScrollOnActive) {
        disableBodyScroll(modalRef);
      }
    }
    hasPresented = true;
  };

  useEffect(() => {
    return () => {
      enableBodyScroll(modalRef);
    };
  }, []);

  const easing = [0.77, 0.0, 0.175, 1.0];
  const variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: easing,
        delay: 0.1,
        delayChildren: 0.1,
        staggerChildren: 0.07
      }
    },
    closed: {
      opacity: 0,
      duration: 0.3,
      x: 50,
      transition: {
        duration: 0.2,
        ease: easing
      }
    }
  };

  if (!hasPresented) {
    controls.start("open");
  }

  const bgColor = (value: string): string => {
    if (!value) {
      return "#ffffff";
    }
    let result = themeGet(`colors.${value}`, value)({ theme: Theme });
    return /^(#|rgb\(|hsl\()/.test(result) ? result : "#ffffff";
  };

  return (
    <Box
      position="fixed"
      width="full"
      height="full"
      zIndex={Z.hamburgerMenu}
      overflowY="auto"
      top="0"
      left="0"
    >
      <motion.div
        key="content"
        style={{
          height: "full",
          minHeight: "100%",
          background: bgColor(rest.bg)
        }}
        initial={"closed"}
        animate={controls}
        variants={variants}
        onAnimationComplete={onAnimationComplete}
      >
        <Flex
          justify="flex-start"
          flexDirection="column"
          minWidth="100%"
          minHeight="100%"
          bg="brand.white.base"
          {...rest}
        >
          {navigation &&
            (navigation === ModalPanelNavigation.back ? (
              <Absolute left="3px" top="8px" onClick={handleCloseClick}>
                <Icon
                  name="chevron-left"
                  width="48px"
                  height="48px"
                  color="brand.white.base"
                  aria-label="Go Back"
                />
              </Absolute>
            ) : (
              <Absolute left="18px" top="20px" onClick={handleCloseClick}>
                <Icon
                  name="close"
                  width="20px"
                  height="20px"
                  color="brand.white.base"
                  aria-label="Close"
                />
              </Absolute>
            ))}
          {children}
        </Flex>
      </motion.div>
    </Box>
  );
};

export default ModalPanel;
