import React, { useState, useEffect } from "react";
import { Box, Stack, Text, Button, Link, MotionBox } from "pws-design-system/design-system";
import Cookies from "js-cookie";

enum SameSiteOptions {
  Strict = "strict",
  Lax = "lax",
  None = "none"
}

interface CookieConsentProps {
  cookieName: string;
  cookieValue: any;
  expires: number;
  sameSite: SameSiteOptions;
}

const getCookieConsentValue = (name: string): any => {
  let value = Cookies.get(name);

  // check for legacy/older browser cookie
  // https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (typeof value === undefined) {
    value = Cookies.get(`${name}-legacy`);
  }

  return `${value}`;
};

const hasConsented = (name: string): boolean => {
  return getCookieConsentValue(name) == "1";
};

const CookieConsent: React.FC<Partial<CookieConsentProps>> = ({
  cookieName = "pws_gdrp_accept",
  cookieValue = "1",
  expires = 365,
  sameSite = SameSiteOptions.Strict,
  ...rest
}: CookieConsentProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  const handleAccept = () => {
    const security = location ? location.protocol === "https:" : true;
    const cookieOptions = { expires, sameSite, secure: security };

    // fallback for older browsers
    if (sameSite === SameSiteOptions.None) {
      Cookies.set(`${cookieName}-legacy`, cookieValue, cookieOptions);
    }

    Cookies.set(cookieName, cookieValue, cookieOptions);
    setIsVisible(hasConsented(cookieName) !== true);
  };

  useEffect(() => {
    setIsVisible(!isHidden);
  }, [isHidden]);

  useEffect(() => {
    setIsHidden(hasConsented(cookieName) === true);
  }, []);

  return isHidden === false ? (
    <Box position="fixed" color="black" left="20px" bottom="20px" right={["20px", null, "auto"]}>
      <MotionBox
        bg="#fff"
        padding="24px"
        rounded="xl"
        boxShadow="lg"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={{
          visible: {
            opacity: 1,
            y: 0
          },
          hidden: {
            opacity: 0,
            y: 50
          }
        }}
        transition={{
          type: "spring",
          damping: 50,
          stiffness: 350
        }}
        onAnimationComplete={(def: any) => {
          if (!isVisible) setIsHidden(true);
        }}
        {...rest}
      >
        <Stack w={["auto", null, "300px"]}>
          <Text fontWeight="bold">Our Site Uses Cookies</Text>
          <Text fontSize="md">
            We use cookies to improve your experience, which may also include cookies from third
            parties. By using our website, you agree to the use of cookies. Learn more in our{" "}
            <Link href="/privacy-policy">
              <strong>privacy policy</strong>
            </Link>
            .
          </Text>
          <Button mt={3} onClick={handleAccept}>
            Accept &amp; Close
          </Button>
        </Stack>
      </MotionBox>
    </Box>
  ) : (
    <></>
  );
};

export default CookieConsent;
