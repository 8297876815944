import React, { FC, useState } from "react";
import LinkModel from "../../../../models/prismic/link/";
import { Link } from "pws-design-system/design-system";
import { Link as ReachLink } from "gatsby";
import isNil from "lodash.isnil";

type PrismicLinkProps = any & {
  link: LinkModel;
  Tag?: FC<any>;
  isActiveCss?: Object;
  rest?: any;
  children?: React.ReactNode;
  variant?: "dark" | "light";
};

export default function PrismicLink({
  link,
  Tag = Link,
  isActiveCss = {},
  variant = "dark",
  children = null,
  ...rest
}: PrismicLinkProps): React.ReactElement {
  if (link.isInternal === true) {
    return (
      <Tag
        to={link.url}
        as={ReachLink}
        key={link.text}
        css={link.isActive && isActiveCss}
        variant={variant}
        {...rest}
      >
        {isNil(children) ? link.text : children}
      </Tag>
    );
  }
  return (
    <Tag
      target="_blank"
      href={link.url}
      key={link.text}
      css={link.isActive && isActiveCss}
      variant={variant}
      {...rest}
    >
      {isNil(children) ? link.text : children}
    </Tag>
  );
}
