import ApiRequest from "@aerisweather/javascript-sdk/dist/network/api/ApiRequest";
import { countries, states } from "@aerisweather/javascript-sdk/dist/utils/strings";
import { css } from "@emotion/react";
import { Link as ReachLink } from "gatsby";
import { motion } from "framer-motion";
import _isNil from "lodash.isnil";
import _throttle from "lodash.throttle";
import {
  Absolute,
  Box,
  Flex,
  Icon,
  Link,
  MotionBox,
  PseudoBox,
  Text,
  TextInput,
  Theme
} from "pws-design-system/design-system";
import React from "react";
import ReactDOM from "react-dom";
import aeris from "../../../api";
import Locations from "../../../models/aeris/place/Locations";
import Stations from "../../../models/aeris/place/Stations";
import { isCoord, isZipcode } from "../../../utils/";
import { messageStore } from "../hooks/useMessages";
import { useTheme, ThemeContainer } from "../hooks/useTheme";

const stateVals = states();
const countryVals = countries();
const allStates = { ...stateVals.us, ...stateVals.ca };

const stateQuery = (val: string): string => {
  const parts: string[] = [];
  const re = new RegExp(`^${val}`, "i");

  if (val.length < 2) {
    parts.push(`state:^${val}`);
  }

  Object.keys(allStates).forEach(k => {
    if (re.test(allStates[k])) {
      parts.push(`state:^${k}`);
    }
  });

  return parts.join(";");
};

const countryQuery = (val: string): string => {
  const parts: string[] = [];
  const re = new RegExp(`^${val}`, "i");

  if (val.length < 2) {
    parts.push(`country:^${val}`);
  }

  Object.keys(countryVals).forEach(k => {
    if (re.test(countryVals[k])) {
      parts.push(`country:^${k}`);
    }
  });

  return parts.join(";");
};

const SearchInput = (props: any) => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <>
      <TextInput
        mt={0}
        onFocus={props.onFocused}
        onBlur={props.onBlur}
        onChange={props.onChange}
        bg={
          props.focused
            ? theme.components.autosuggest.input.bgFocused
            : theme.components.autosuggest.input.bg
        }
        border="none"
        focusBorderColor="transparent"
        color={theme.components.autosuggest.input.color}
        placeholder={messageStore.autosuggest_placeholder}
        value={props.value}
        rounded="full"
        leftElement={<Icon name="search" color={theme.components.autosuggest.input.icon.bg} />}
        _hover={{
          borderColor: "transparent"
        }}
        css={css`
          ::-webkit-input-placeholder {
            color: ${theme.components.autosuggest.input.placeholder.color};
          }
        `}
        {...props}
      />
    </>
  );
};

const SearchResult = (props: any) => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <>
      {_isNil(props.model.url) !== true && (
        <Link
          width="100%"
          disableUnderline={true}
          href={`/${props.model.placeType === "location" ? "local" : "station"}/${props.model.url}`}
          // as={ReachLink}
          _hover={{ textDecoration: "none" }}
        >
          <PseudoBox
            px={2}
            py={2}
            borderBottomColor={theme.components.autosuggest.result.borderColor}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            _hover={{
              bg: theme.components.autosuggest.result.hover.bg,
              cursor: "pointer"
            }}
            {...props}
          >
            <Text fontSize="md" color={theme.components.autosuggest.listing.color}>
              {props.model.displayId}
            </Text>
          </PseudoBox>
        </Link>
      )}
    </>
  );
};

const SearchResultListing = ({ results, orientation = "horizontal" }: any) => {
  const { theme } = ThemeContainer.useContainer();
  if (_isNil(results) === true) {
    return null;
  }

  const isHorizontal = orientation === "horizontal";
  const colWidth = isHorizontal ? "50%" : "100%";
  const colStyles = isHorizontal ? { mr: 2 } : { mb: 4 };

  const content = (
    <>
      <Box width={colWidth} {...colStyles}>
        <Text
          mb={1}
          px={2}
          py={1}
          bg="brand.green.base"
          color={theme.components.autosuggest.listing.color}
          variant="label"
          fontSize="12px"
        >
          Places
        </Text>
        {results.locations && results.locations.hasData === true ? (
          results.locations.models.map((model: any) => (
            <>
              <SearchResult model={model}></SearchResult>
            </>
          ))
        ) : (
          <Text color="brand.gray.600" variant="callout" pt={2} px={2}>
            {messageStore.autosuggest_no_locations}
          </Text>
        )}
      </Box>
      <Box width={colWidth}>
        <Text
          mb={1}
          px={2}
          py={1}
          bg="brand.green.base"
          color="white"
          variant="label"
          fontSize="12px"
        >
          Stations
        </Text>
        {results.stations && results.stations.hasData === true ? (
          results.stations.models.map((model: any) => <SearchResult model={model}></SearchResult>)
        ) : (
          <Text color="brand.gray.600" variant="callout" pt={2} px={2}>
            {messageStore.autosuggest_no_stations}
          </Text>
        )}
      </Box>
    </>
  );

  if (isHorizontal) {
    return (
      <Flex
        width="100%"
        justify="space-between"
        align="flex-start"
        overflowY="auto"
        maxHeight="calc(100% - 43px)"
      >
        {content}
      </Flex>
    );
  }

  return (
    <Box width="100%" overflowY="auto" maxHeight="calc(100% - 43px)">
      {content}
    </Box>
  );
};

type Props = any & {
  focusStyle?: any;
  resultsOrientation?: "horizontal" | "vertical";
  showOverlay?: boolean;
  onSearching?: (isSearching: boolean) => void;
};

type State = any & {};

export default class SearchField extends React.Component<Props, State> {
  maxWidth = 1000;
  inset = 10;

  textInput: any;
  fieldContainer: any;
  locationsApi: ApiRequest;
  stationsApi: ApiRequest;
  lastQuery: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      value: "",
      width: 0,
      focused: false,
      results: false,
      searchResults: null,
      isActive: false,
      isOpen: false
    };

    this.textInput = React.createRef();
    this.fieldContainer = React.createRef();
    this.locationsApi = aeris.api().endpoint("places");
    this.stationsApi = aeris.api().endpoint("observations");
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.resizeCallback);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCallback);
  }

  setContainerRef = (element: any) => {
    // console.log("set ref", element);
  };

  resizeCallback = _throttle(
    () => {
      this.updateWidth();
    },
    200,
    { leading: true }
  ).bind(this);

  updateWidth() {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;
    if (node) {
      const { innerWidth: maxWidth } = window;
      const rect = node.getBoundingClientRect();

      this.maxWidth = maxWidth - 20;

      this.setState({
        width: Math.min(rect.width, this.maxWidth)
      });
    }
  }

  setContainerRef = (element: any) => {
    // console.log("set ref", element);
  };

  reset() {
    this.locationsApi.cancel();
    this.locationsApi.resetParams();
    this.stationsApi.cancel();
    this.stationsApi.resetParams();
  }

  async search(query: string) {
    this.reset();

    // don't perform request if query matches lastQuery
    if (query === this.lastQuery) {
      return;
    }
    this.lastQuery = query;
    let doSearch = true;

    // obs station request
    this.stationsApi
      .action("search")
      .query(
        `id:^${query.toUpperCase()},datasource:!MADIS_METAR,datasource:!MADIS_HFMETAR,trustfactor:0,qccode:10`
      )
      .limit(50)
      .from("-20days")
      .filter("metar;pws;madis;ausbom,allownosky");

    // place request
    if (isCoord(query)) {
      this.locationsApi.action("closest").place(query);
    } else if (isZipcode(query)) {
      //reset to remove the search action as zip can't be searched
      this.locationsApi = aeris.api().endpoint("places");
      this.locationsApi.place(query);
    } else {
      const parts = query.split(",");
      if (parts.length > 1) {
        query = `name:${parts[0]};altname:${parts[0]}`;

        if (parts.length === 3) {
          query += `,${stateQuery(parts[1])},${countryQuery(parts[2])}`;
        } else if (parts.length === 2 && parts[1].length > 0) {
          const val = parts[1];
          const stateStr = stateQuery(val);
          const countryStr = countryQuery(val);

          if (stateStr && stateStr.length > 0) {
            query += `,${stateStr};${countryStr}`;
          } else if (countryStr.length > 0) {
            query += `,${countryStr}`;
          }
        }
      } else {
        query = `name:^${parts[0]};altname:^${parts[0]}`;
      }

      if (isZipcode(query)) {
        this.locationsApi
          .query(query)
          .limit(50)
          .sort("pop:-1,haszip:-1");
      } else {
        this.locationsApi
          .action("search")
          .query(query)
          .limit(50)
          .sort("pop:-1,haszip:-1");
      }
    }

    let locations: Locations = null;
    let stations: Stations = null;

    if (doSearch) {
      const [locationsResponse, stationsResponse] = await Promise.all([
        this.locationsApi.get(),
        this.stationsApi.get()
      ]);
      locations = new Locations({
        data: Array.isArray(locationsResponse.data)
          ? locationsResponse.data
          : [locationsResponse.data]
      });
      stations = new Stations({ data: stationsResponse.data });
    }

    this.setState({
      isActive: true,
      isOpen: true,
      results: true,
      searchResults: { locations, stations }
    });
  }

  // field events
  onFocused = () => {
    this.setState({
      focused: true
    });
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({
        value: "",
        focused: false,
        results: false,
        isOpen: false
      });
      if (this.props.onSearching) {
        this.props.onSearching(false);
      }
    }, 300);
  };

  onChange = (e: any) => {
    let query = e.target.value;

    this.setState({
      value: query
    });

    // remove leading and trailing space
    query = query.replace(/^\s+/, "").replace(/\s+$/, "");
    // replace spaces after commas
    query = query.replace(/,\s+/, ",");
    // replace trailing comma
    query = query.replace(/,$/, "");

    if (query.length >= 3) {
      this.search(query);
      if (this.props.onSearching) {
        this.props.onSearching(true);
      }
    } else {
      this.lastQuery = null;
      this.setState({
        results: false,
        isOpen: false
      });
      if (this.props.onSearching) {
        this.props.onSearching(false);
      }
    }
  };

  render() {
    if (this.state.width === 0) {
      return <div></div>;
    }

    const targetWidth = this.state.width - this.inset * 2;
    const expandedWidth = this.props.resizeOnFocus
      ? 600
      : this.props.expandedWidth || this.state.width;

    let styles: any = {};
    if (this.props.fieldStyles) {
      const { base, focus, blur } = this.props.fieldStyles;
      if (base) {
        styles = { ...styles, ...base };
      }

      if (this.state.results === false) {
        if (this.state.focused) {
          styles = { ...styles, ...focus };
        } else if (this.state.blur) {
          styles = { ...styles, ...blur };
        } else {
          styles.borderColor = "transparent";
        }
      } else {
        styles.borderColor = "transparent";
      }
    }

    return (
      <>
        {this.props.showOverlay && this.state.isActive && (
          <motion.div
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0,0.8)",
              zIndex: 100
            }}
            variants={{
              active: {
                opacity: 1
              },
              inactive: {
                opacity: 0
              }
            }}
            animate={this.state.isOpen ? "active" : "inactive"}
            initial={"inactive"}
            transition={{
              duration: 0.3,
              ease: "easeOut"
            }}
            onAnimationComplete={() => {
              if (!this.state.isOpen) {
                this.setState({
                  isActive: false
                });
              }
            }}
          />
        )}
        <Box
          position="relative"
          zIndex={this.state.isActive ? 101 : "unset"}
          height={styles.height || "40px"}
        >
          <Absolute
            zIndex={this.state.isActive ? 1001 : "unset"}
            anchor="center-x"
            width={this.props.resizeOnFocus ? "100%" : this.state.width - this.inset * 2}
          >
            <motion.div
              style={
                this.props.resizeOnFocus
                  ? {
                      width: `${targetWidth}px`
                    }
                  : {}
              }
              animate={
                this.props.resizeOnFocus
                  ? this.state.focused
                    ? {
                        width: expandedWidth,
                        x: -Math.round((expandedWidth - targetWidth) / 2)
                      }
                    : {
                        width: targetWidth,
                        x: 0
                      }
                  : {}
              }
              transition={{
                type: "spring",
                damping: 50,
                stiffness: 250
              }}
            >
              <SearchInput
                value={this.state.value}
                focused={this.state.focused}
                onFocus={this.onFocused}
                onBlur={this.onBlur}
                onChange={this.onChange}
                {...styles}
              />
            </motion.div>
          </Absolute>
          {this.state.focused && (
            <Absolute anchor="center-x" top={["-5px", null, "-10px"]} zIndex={1000}>
              <MotionBox
                bg={this.props.theme.components.autosuggest.listing.bg}
                overflow="hidden"
                borderColor="brand.green.base"
                borderWidth={4}
                borderStyle="solid"
                p={3}
                rounded="20px"
                initial="hidden"
                animate={this.state.results ? "visible" : "hidden"}
                variants={{
                  visible: {
                    opacity: 1,
                    y: 0,
                    width: Math.min(expandedWidth + 40, this.maxWidth),
                    height: 350,
                    boxShadow: "0 0 2px 0 rgba(0,0,0,.04),0 4px 16px 0 rgba(0,0,0,.16)",
                    borderRadius: "20px"
                  },
                  hidden: {
                    opacity: 0,
                    y: 10,
                    width: expandedWidth,
                    height: 40,
                    boxShadow: "0",
                    borderRadius: "20px"
                  }
                }}
                transition={{
                  type: "spring",
                  damping: 50,
                  stiffness: 350
                }}
              >
                <Box mt="30px" mb={3} height="3px" bg="brand.gray.700" />
                <SearchResultListing
                  results={this.state.searchResults}
                  orientation={this.props.resultsOrientation}
                />
              </MotionBox>
            </Absolute>
          )}
        </Box>
      </>
    );
  }
}
