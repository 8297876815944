import React, { useState, useEffect, useRef } from "react";
import useUser from "../../hooks/useUser";
import DashboardDropdownDisplay from "./LoginLinkDesktopDisplay";
import HamburgerDisplay from "./HamburgerDisplay";

const variants = {
  DesktopDropdown: DashboardDropdownDisplay,
  Hamburger: HamburgerDisplay
};
interface DashboardDropdownProps {
  rest: any;
  Variant?: string;
  styleVariant?: "dark" | "light";
}

export default function DashboardDropdown({
  stations,
  variant = "DesktopDropdown",
  ...rest
}: DashboardDropdownProps): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const user = useUser();
  // const savedHandler = useRef(null);
  const Variant = variants[variant];

  function handleClick(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsActive(!isActive);
  }

  return (
    <Variant
      user={user}
      style={{ whiteSpace: "nowrap" }}
      isActive={isActive}
      clickHandler={handleClick}
      {...rest}
    />
  );
}
