import Model from "../Model";
import schema from "./schema";
import * as yup from "yup";

interface ILink {
  link_type: string;
  link: {
    url?: string | null;
  };
  document?: {
    type: string;
    uid?: string;
  };
}

const BASE_URL = "https://www.pwsweather.com";

function linkResolver(link: ILink): string {
  if (link.link_type === "Document") {
    return `/${link.document.uid}`;
  }
  if (link.link_type === "Web") {
    if (link.url.startsWith(BASE_URL) === true) {
      return link.url.replace(BASE_URL, "");
    } else {
      return link.url;
    }
  }
}

export enum LinkDisplayRuleEnum {
  Authenticated = "Authenticated",
  Unauthenticated = "Unauthenticated",
  Everyone = "Everyone"
}

type LinkDisplayRuleKeys = "Authenticated" | "Unauthenticated" | "Everyone";

export default class Link extends Model {
  protected schema: yup.ObjectSchema = schema;
  public url: string;
  constructor(apiData) {
    super(apiData);
    this.url = linkResolver(apiData.link);
  }
  public get text(): string {
    return this.get("link_text");
  }
  public get style(): "primary" | "secondary" {
    return this.get("link_style");
  }
  public get isActive(): boolean {
    const isBrowser = typeof window !== "undefined" && window;
    if (isBrowser) {
      return window.location.pathname === this.url;
    }
    return false;
  }
  public get isHome(): boolean {
    return this.url === "/";
  }
  public get isInternal(): boolean {
    if (!this.url) {
      return true;
    }
    return this.url.startsWith("/") === true || this.url.startsWith(BASE_URL) === true;
  }
  public get displayRule(): LinkDisplayRuleKeys {
    return this.get("link_display_rule");
  }
}
