import { css, Global } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { graphql, useStaticQuery } from "gatsby";
import _isNil from "lodash.isnil";
import { Absolute, Box, Hide } from "pws-design-system/design-system";
import React, { useState } from "react";
import { Z } from "../../../types/enums";
import Meta from "../../common/components/meta";
import Footer from "../../common/landmarks/footer";
import Header from "../../common/landmarks/headers/Header";
import {
  AnnouncementBlock,
  blockHeight as announcementHeight
} from "../components/announcement/Announcement";
import { matchesMediaQuery } from "../components/responsive-render/ResponsiveRender";
import HamburgerMenu from "../landmarks/hamburger-menu";
import Layout from "./Layout";
import { Announcements as AnnouncementCollection } from "../../../models/prismic/announcement";
import { CookieConsent } from "../components/cookie-consent";

Sentry.init({ dsn: "https://f6a7f2f03b7a402384a9d7abed5dc26c@sentry.io/1890432" });

type BackgroundImageProps = any & {
  height?: number;
  width?: number;
  component?: any;
};

type PageLayoutProps = any & {
  page?: any;
  children: any;
  includeFooter?: boolean;
  headerProps?: any;
  contentProps?: any;
  footerProps?: any;
  containerProps?: any;
  background?: BackgroundImageProps;
  showTitleSuffix: boolean;
  manifest: string;
};

const PageLayout = ({
  page,
  children,
  includeFooter = true,
  headerProps,
  contentProps,
  footerProps,
  containerProps,
  background,
  metaTitle,
  metaDescription,
  manifest,
  showTitleSuffix,
  ...rest
}: PageLayoutProps): React.ReactElement => {
  headerProps = {
    bg: "white",
    pt: [3, null, 4, 5],
    pb: [5, null, 5, "90px"],
    ...headerProps
  };
  contentProps = {
    bg: "white",
    ...contentProps
  };
  containerProps = {
    bg: "#1a415f",
    ...containerProps
  };

  const backgroundProps = {
    height: "100%",
    width: "1500px",
    ...background
  };

  delete backgroundProps.component;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isMobile = matchesMediaQuery("mobile");
  const bodyColor = isMobile ? "#fff" : "#1c425c";

  const announcementApiData = useStaticQuery(graphql`
    query {
      ...Announcements
    }
  `);
  const announcements = new AnnouncementCollection(announcementApiData);
  return (
    <Layout>
      <Meta
        title={metaTitle}
        description={metaDescription}
        showTitleSuffix={showTitleSuffix}
        manifest={manifest}
      />
      <Global
        styles={css`
          .grecaptcha-badge {
            display: none;
          }
        `}
      />
      <AnnouncementBlock announcements={announcements} zIndex={Z.announcement} />
      <Box
        overflow="unset"
        {...containerProps}
        position="relative"
        pt={
          _isNil(containerProps.pt) === false && !isMobile
            ? containerProps.pt
            : announcements.models.length
            ? `${announcementHeight()}px`
            : "0px"
        }
      >
        {background && (
          <Absolute
            bottom="0"
            left="50%"
            margin="0 auto"
            maxWidth="100%"
            top="0"
            transform="translateX(-50%)"
            zIndex={0}
            {...backgroundProps}
          >
            {background.component}
          </Absolute>
        )}
        <Header handleOpenMenu={handleOpenModal} {...headerProps} />
        <Box {...contentProps}>{children}</Box>
        {includeFooter && (
          <Hide xs sm>
            <Footer {...footerProps}></Footer>
          </Hide>
        )}
      </Box>
      <CookieConsent />
      {isModalOpen && <HamburgerMenu isOpen={isModalOpen} handleClose={handleCloseModal} />}
    </Layout>
  );
};

export default PageLayout;
