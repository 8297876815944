import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import _get from "lodash.get";

const DesktopLogoutLinkDisplay = () => <>Logout</>;
const MobileLogoutLinkDisplay = () => <>Logout</>;

interface LogoutLinkProps {}

/**
 *
 * @param {LogoutLinkProps} props.
 * @returns {React.ReactElement}
 */
const LogoutLink: React.FC<LogoutLinkProps> = (): React.ReactElement => {
  const onClick = async () => {
    try {
      await axios.get(`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_LOGOUT_URL}`, {
        withCredentials: true
      });
    } catch (e) {}
    localStorage.setItem("authToken", null);
    window.location.reload();
  };

  return (
    <span onClick={onClick} style={{ cursor: "pointer" }}>
      Logout
    </span>
  );
};

export { LogoutLink };
