import { object, array, string } from "yup";

export default object()
  .required()
  .shape({
    link: object()
      .required()
      .shape({
        url: string().nullable(),
        link_type: string().nullable(),
        document: object()
          .nullable()
          .shape({
            type: string(),
            uid: string()
          })
      }),
    link_text: string().required(),
    link_style: string()
  });
