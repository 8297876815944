import React from "react";
import {
  BlockLink,
  Box,
  Section,
  Grid,
  Flex,
  Link,
  Stack,
  LogoMark
} from "pws-design-system/design-system";
import _get from "lodash.get";
import { useStaticQuery, graphql } from "gatsby";
import NavigationModel from "../../../../models/prismic/navigation/";
import { Link as ReachLink } from "gatsby";
import LoginLink from "../../../common/components/login-link/";
import Navigation from "../../components/navigation/";

function useQuery(): {
  mainNavigation: {};
  footerNavigation: {};
} {
  const data = useStaticQuery(
    graphql`
      query {
        ...MainNavigation
        ...FooterSubnavigation
      }
    `
  );

  return Object.keys(data).reduce(
    (prev, curr) => {
      const item = data[curr];
      const itemData = _get(item, "edges[0].node.data", null);
      (prev as any)[curr] = itemData;
      return prev;
    },
    { mainNavigation: null, footerNavigation: null }
  );
}

export const SubNavigationLinks = (): React.ReactElement => {
  const { footerNavigation } = useQuery();
  const subNavigation = new NavigationModel(footerNavigation);

  if (subNavigation.has("links")) {
    return (
      <Stack spacing={4} isInline>
        {subNavigation.links.map((link: any) => {
          if (link.isInternal === true) {
            return (
              <Link to={link.url} as={ReachLink} key={link.text}>
                {link.text}
              </Link>
            );
          } else {
            return (
              <Link target="_blank" href={link.url} key={link.text}>
                {link.text}
              </Link>
            );
          }
        })}
      </Stack>
    );
  }
  return <></>;
};

type FooterProps = any & {
  variant?: string;
};

export default function Footer({ variant, ...rest }: FooterProps) {
  const { mainNavigation } = useQuery();
  const navigation = new NavigationModel(mainNavigation);

  return (
    <Box color="brand.white.base" {...rest}>
      {variant !== "minimal" && (
        <>
          {navigation.has("links") && (
            <Section bg="#1f4968" py={4}>
              <Grid>
                <Flex fontSize="md" align="center">
                  <BlockLink as={ReachLink} to="/" mr={[2, null, 5]}>
                    <LogoMark variantColor="light" width="50px" />
                  </BlockLink>
                  <Navigation
                    variant="light"
                    includeHome={false}
                    links={navigation.links}
                    inline={true}
                    SuffixLinkSlot={(props: any) => (
                      <LoginLink styleVariant="light" fontSize="sm" fontWeight="bold" {...props} />
                    )}
                  />
                </Flex>
              </Grid>
            </Section>
          )}
        </>
      )}

      <Section bg="#1c425c" py={3}>
        <Grid>
          <Flex fontSize="xs" justify="space-between">
            <Box>
              © {new Date().getFullYear()}{" "}
              <Link href="https://www.xweather.com" target="_blank">
                Vaisala Xweather
              </Link>
            </Box>
            <SubNavigationLinks />
          </Flex>
        </Grid>
      </Section>
    </Box>
  );
}
