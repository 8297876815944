import { useState } from "react";
import { createContainer } from "unstated-next";
import * as yup from "yup";
import { PreferenceKeys } from "../../../../types/enums";
import PersistentStore from "./PersistentStore";

class AuthTokenStore extends PersistentStore {
  key = PreferenceKeys.AuthToken;

  protected defaults: {
    authToken: null;
  };

  protected schema = yup
    .object()
    .required()
    .shape({
      authToken: yup.string()
    });

  get authToken() {
    return this.getValue("authToken");
  }
}

export const authTokenStore = new AuthTokenStore();

export const useAuthTokenStore = () => {
  const [authToken, setAuthTokenState] = useState(authTokenStore.authToken);

  const setAuthToken = (value: string) => {
    authTokenStore.setValue("authToken", value);
    setAuthTokenState(value);
  };

  return { authToken, setAuthToken };
};

export const AuthTokenStoreContainer = createContainer(useAuthTokenStore);
