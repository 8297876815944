import { useState, useEffect } from "react";
import axios from "axios";
import _isNil from "lodash.isnil";
import UserModel from "../../../models/pws/User";
import { useAuthTokenStore } from "./stores/useAuthTokenStore";

export default function useUser(initialUser = new UserModel()) {
  const [user, setUser] = useState(initialUser);
  const { authToken } = useAuthTokenStore();

  useEffect(() => {
    const headers = authToken
      ? {
          Authorization: `Bearer ${authToken}`
        }
      : {};
    async function fetchUser() {
      let user;
      try {
        const response = await axios.get(
          `${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_ACCOUNT_URL}`,
          {
            withCredentials: true,
            headers
          }
        );
        if (response.data.success === false) {
          user = new UserModel({ data: { success: false } });
        } else {
          user = new UserModel({ data: response.data });
        }
        setUser(user);
      } catch (e) {
        user = new UserModel({ data: { success: false } });
        setUser(user);
      }
    }
    fetchUser();
  }, []);
  return user;
}
