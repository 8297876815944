import React, { useState, useLayoutEffect } from "react";
import { useStaticQuery, graphql, StaticQuery } from "gatsby";
import { css } from "@emotion/react";
import {
  Absolute,
  BlockLink,
  Box,
  Flex,
  Hide,
  Icon,
  Theme,
  Text,
  Grid,
  Col,
  Row
} from "pws-design-system/design-system";
import {
  Announcements as AnnouncementCollection,
  Announcement as AnnouncementModel
} from "../../../../models/prismic/announcement/";
import PrismicLink from "../../../common/components/prismic-link";
import _isNil from "lodash.isnil";
import { Z } from "../../../../types/enums";

type AnnouncementProps = {
  model: AnnouncementModel;
};

type AnnouncementColor = "green" | "yellow" | "red";

const rowHeight = 30;

export const blockHeight = (): number => {
  // return announcements.models.length * rowHeight;
  return rowHeight;
};

const Announcement = ({ model }: AnnouncementProps): React.ReactElement => {
  const colors = {
    green: Theme.colors.brand.green.base,
    yellow: Theme.colors.yellow["200"],
    red: Theme.colors.red["600"]
  };

  const textColor = {
    green: Theme.colors.brand.white.base,
    yellow: Theme.colors.brand.gray.base,
    red: Theme.colors.brand.white.base
  };

  if (model.isValid === false) {
    return null;
  }

  return (
    <Flex
      background={colors[model.color as AnnouncementColor]}
      width="100%"
      justify="center"
      py={1}
    >
      <Grid>
        <Row>
          <Col>
            {_isNil(model.link) ? (
              <Text
                fontWeight="bold"
                color={textColor[model.color as AnnouncementColor]}
                pl={[3, null, 2]}
              >
                {model.text}
              </Text>
            ) : (
              <PrismicLink
                link={model.link}
                Tag={BlockLink}
                fontSize="13px"
                fontWeight="bold"
                color={textColor[model.color as AnnouncementColor]}
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                css={css`
                  svg {
                    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
                  }
                  &:hover svg {
                    transform: translateX(3px);
                  }
                `}
              >
                <Flex align="center">
                  {model.text} <Icon name="chevron-right" size="24px" />
                </Flex>
              </PrismicLink>
            )}
          </Col>
        </Row>
      </Grid>
    </Flex>
  );
};

type AnnouncementBlockProps = {
  announcements: AnnouncementCollection;
  zIndex: Z;
};
export const AnnouncementBlock = (props: AnnouncementBlockProps): React.ReactElement => {
  const { announcements, zIndex } = props;
  if (announcements.models.length === 0) {
    return null;
  }

  return (
    <Box position="fixed" top="0" width="100%" {...props}>
      {announcements.models.map((announcement: any, index: number) => (
        <Announcement model={announcement} key={`announce-${index}`} />
      ))}
    </Box>
  );
};

export default Announcement;
