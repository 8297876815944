import React from "react";
import Helmet from "react-helmet";
import { messageStore } from "../../hooks/useMessages";

interface MetaProps {
  title?: string;
  description?: string;
  manifest: string;
  showTitleSuffix?: boolean;
}

export default function({
  title,
  description = "",
  manifest = "/manifest.webmanifest",
  showTitleSuffix = true
}: MetaProps): React.ReactElement {
  if (!title) {
    return null;
  }
  return (
    <Helmet>
      <title>
        {title}
        {showTitleSuffix === true ? ` ${messageStore.global_meta_title_suffix}` : ""}
      </title>
      <meta name="description" content={description} />
      <link rel="manifest" href={manifest} />
    </Helmet>
  );
}
