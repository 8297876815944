import { object, array, string } from "yup";

export default object()
  .required()
  .shape({
    announcement_color: string().matches(/(Green|Yellow|Red)/),
    announcement_link: object()
      .required()
      .shape({
        document: object()
          .nullable()
          .shape({
            uid: string(),
            type: string()
          }),
        url: string().nullable(),
        uid: string().nullable(),
        link_type: string()
          .required()
          .matches(/(Document|Web|Any)/)
      }),
    announcement_text: string().required()
  });
