export enum StationType {
  Metar = "metar",
  Aus = "aus",
  Pws = "pws",
  Madis = "madis"
}

export enum StationTypePrefix {
  Pws = "pws_",
  Madis = "mid_",
  Aus = "aus_"
}
