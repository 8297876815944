import schema from "./schema";
import * as yup from "yup";
import "./query";
import Model from "../Model";
import Link from "../link/";
import _isNil from "lodash.isnil";

export default class Navigation extends Model {
  protected schema: yup.ObjectSchema = schema;
  public links: Link[] = null;
  constructor(protected apiData: object) {
    super(apiData);
    const data = this.get("links");
    if (_isNil(data) !== true) {
      this.links = data.map(link => new Link(link)).filter(link => link.isValid);
    }
  }
}
