import _isNil from "lodash.isnil";

export default abstract class Collection {
  models: any[] = null;
  data: any = null;
  public isCollection: true = true;

  constructor(config: any) {
    if (config.data) {
      this.data = config.data;
    }
  }

  public has(attribute: string): boolean {
    return _isNil(this[attribute]) === false;
  }

  public get hasData(): boolean {
    return this.models !== null;
  }
}
