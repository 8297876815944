import React, { useState } from "react";
import { Link as ReachLink } from "gatsby";
import { Avatar, Box, Flex, Link, Icon, Text, Stack } from "pws-design-system/design-system";
import ModalPanel, { ModalPanelNavigation } from "../modal-panel/ModalPanel";
import { LogoutLink } from "../../components/logout-link";
import { useAuthTokenStore } from "../../hooks/stores/useAuthTokenStore";

export type DashboardDropdownDisplayProps = any & {
  user?: any;
  styleVariant?: string;
  clickHandler: () => void;
};

const DashboardDropdownDisplay = ({
  user,
  clickHandler,
  styleVariant,
  ...rest
}: DashboardDropdownDisplayProps) => {
  const [panelIsActive, setPanelIsActive] = useState(false);
  const { authToken } = useAuthTokenStore();

  const handleClose = () => {
    setPanelIsActive(!panelIsActive);
  };

  const handleClick = () => {
    setPanelIsActive(!panelIsActive);
  };

  if (user.hasData === true && user.isAuthenticated === true) {
    return (
      <>
        <Box position="relative" mx={3}>
          <Flex cursor="pointer" align="center" justify="space-between" onClick={handleClick}>
            <Box>Account</Box>
            <Avatar size="md" src="" />
          </Flex>
        </Box>
        {panelIsActive && (
          <ModalPanel
            bg="brand.white.base"
            isActive={panelIsActive}
            handleClose={handleClose}
            navigation={ModalPanelNavigation.back}
          >
            <Flex
              px={4}
              pt={5}
              pb={3}
              bg="brand.lightBlue.base"
              color="white"
              align="flex-end"
              justify="space-between"
            >
              <Box>
                <Text
                  mt={3}
                  variant="caption1"
                  color="brand.darkBlue.base"
                  fontWeight="bold"
                  textTransform="uppercase"
                  letterSpacing="wider"
                >
                  Logged in as
                </Text>
                <Text mt={2} variant="headline" fontWeight="semibold">
                  {user.getData("response.user.username")}
                </Text>
              </Box>
              <Avatar mb={2} cursor="pointer" size="md" src="" />
            </Flex>
            <Box p={4} fontSize="24px">
              <Stack>
                <Link
                  fontSize="title1"
                  href={`${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authToken}`}
                >
                  Manage Stations
                </Link>
                <LogoutLink>
                  <Text fontSize="title1" fontWeight="bold">
                    Logout
                  </Text>
                </LogoutLink>
              </Stack>
              <Box my={3} bg="brand.gray.300" height="2px" />
              <Text
                variant="caption1"
                color="brand.darkBlue.base"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="wider"
              >
                Stations
              </Text>
              <Stack mt={3} spacing={3}>
                {user.stations &&
                  user.stations.map((station: any) => (
                    <Link
                      variant="dark"
                      key={station.stationId.toLowerCase()}
                      to={`/station/pws/${station.stationId.toLowerCase()}`}
                      as={ReachLink}
                    >
                      {station.stationId}
                    </Link>
                  ))}
              </Stack>
            </Box>
          </ModalPanel>
        )}
      </>
    );
  }
  return (
    <Link as={ReachLink} to="/login" variant={styleVariant} {...rest}>
      Log In
    </Link>
  );
};

export default DashboardDropdownDisplay;
