import React, { useState } from "react";
import { Link as ReachLink } from "gatsby";
import {
  Box,
  Col,
  Flex,
  Grid,
  Hide,
  Logo,
  Row,
  IconButton,
  BlockLink,
  LogoMark
} from "pws-design-system/design-system";
import AutoSuggest from "../../components/AutoSuggest";
import MainNavigation from "../main-navigation/";
import { Z } from "../../../../types/enums";
import { ThemeContainer } from "../../hooks/useTheme";
import _get from "lodash.get";

type HeaderProps = any & {
  variant?: "light" | "dark";
  logoVariant?: "light" | "dark";
  includeLogo?: boolean;
  includeSearch?: boolean;
  handleOpenMenu?: () => void;
  context: string;
};

const Header = ({
  variant = "dark",
  logoVariant,
  includeLogo = true,
  includeSearch = true,
  handleOpenMenu,
  context = "page",
  ...rest
}: HeaderProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMenuClick = () => {
    if (handleOpenMenu) {
      handleOpenMenu();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box {...rest} position="relative">
      <Hide xs sm md lg>
        <Grid>
          <Row>
            <Col sm={12} lg={4}>
              {includeLogo && (
                <BlockLink as={ReachLink} to="/" mt="-12px">
                  <Logo
                    height={50}
                    variantColor={_get(theme, `components.${context}.logo.variant`, "dark")}
                    includeAeris={true}
                  />
                </BlockLink>
              )}
            </Col>
            <Col sm={12} lg={4}>
              {includeSearch && (
                <AutoSuggest
                  resizeOnFocus={true}
                  theme={theme}
                  fieldStyles={{
                    base: {
                      border: "4px solid transparent"
                    },
                    focus: {
                      borderColor: "#51b7d4",
                      _hover: {
                        borderColor: "#51b7d4"
                      }
                    }
                  }}
                />
              )}
            </Col>
            <Col lg={4}>
              <Flex
                fontSize="md"
                justify="flex-end"
                mt={3}
                color={_get(theme, `components.${context}.mainNavigation.color`, "brand.gray.base")}
              >
                <MainNavigation variant={variant} />
              </Flex>
            </Col>
          </Row>
        </Grid>
      </Hide>
      <Hide xl xxl>
        <Grid>
          <Row>
            <Col>
              <Flex justify="space-between">
                {includeLogo ? (
                  <BlockLink as={ReachLink} to="/">
                    <LogoMark
                      width="50px"
                      height="50px"
                      variantColor={_get(
                        theme,
                        `components.${context}.logo.mobile.variant`,
                        "dark"
                      )}
                    />
                  </BlockLink>
                ) : (
                  <Box />
                )}
                <IconButton
                  mr="-5px"
                  color={theme.name === "light" ? "black" : "white"}
                  size="md"
                  variant="ghost"
                  width="40px"
                  height="40px"
                  icon="menu"
                  aria-label="Site Navigation"
                  onClick={handleMenuClick}
                />
              </Flex>
            </Col>
          </Row>
        </Grid>
      </Hide>
    </Box>
  );
};

export default Header;
